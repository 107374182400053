<template>
  <div id="app">
    <div>
      <div class="header">
        <div class="fuction">
          <img src="@/assets/homebackimg.jpg" width="100%" />

          <div class="setting">
            <!-- <div class="regist">
            <el-popover placement="bottom" width="200">
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
                <el-form-item>
                  <el-input v-model="ruleForm.username" placeholder="昵称"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-input v-model="ruleForm.password" placeholder="密码"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-input v-model="ruleForm.phone" placeholder="手机号"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="regist">注册</el-button>
                </el-form-item>
              </el-form>

              <el-button slot="reference" class="regist-btn">注册</el-button>
            </el-popover>
          </div> -->

            <!-- <div class="longin">
            <el-popover placement="bottom" width="200">
              <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
                <el-form-item>
                  <el-input v-model="ruleForm.username" placeholder="昵称"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-input v-model="ruleForm.password" placeholder="密码"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="longin">登录</el-button>
                </el-form-item>
              </el-form>
              <el-button slot="reference" class="regist-btn">登录</el-button>
            </el-popover>
          </div> -->

            <!-- <div class="portrait">
            <el-popover placement="bottom" width="100">
              <el-form status-icon ref="ruleForm" label-width="0px" class="demo-ruleForm">
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">修改密码</el-button>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">退出</el-button>
                </el-form-item>
              </el-form>

              <el-button slot="reference" icon="el-icon-s-custom"> </el-button>
            </el-popover>
          </div> -->
          </div>
        </div>
      </div>

      <div class="header_wrap w">
        <router-link to="/index">首页</router-link>
        <router-link to="/about">关于活动</router-link>
        <router-link to="/dynamic">活动动态</router-link>
        <router-link :to="`/challenge?id=${challengeId}`">人工智能挑战</router-link>
        <router-link :to="`/communicate?id=${communicateId}`">科技外交官摇篮计划</router-link>
        <router-link :to="`/evtrain?id=${trainId}`">科学沙龙</router-link>
        <router-link :to="`/show?id=${showId}`">活动服务尊享套餐</router-link>
        <router-link :to="`/collect?id=${collectId}`">科学记录者</router-link>
      </div>
    </div>

    <div class="center">
      <transition :key="$route.fullPath" name="fade-transform" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>

    <!-- 底部区域 -->
    <div class="bottom">
      <div class="wrap">
        <p v-for="(item, index) in bottom" :key="index">
          <a :class="item.icon"></a>
          <a :href="item.mainUrl">{{ item.main }}</a>
          <a href="#" target="_blank">{{ item.full }}</a>
        </p>
      </div>

      <div class="guide">
        <div class="title">
          <a class="el-icon-office-building">协办单位（排名不分先后）:</a>
        </div>
        <a href="#" target="_blank" v-for="(item, index) in guide" :key="index">{{item}}</a>
      </div>

      <div class="link">
        <img src="@/assets/zggjkxjshzxh.png" alt="" width="190px" height="50px" @click="friendLink" />
      </div>

      <div class="bottom-code">
        <img src="@/assets/TK-Wechat.jpeg" width="100px" height="100px" />
        <span class="wechat">抖音 扫一扫</span>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/https'

  export default {
    components: {},

    data() {
      return {
        activeName: 'first',
        banner: [],

        bottom: [{
            icon: 'el-icon-office-building',
            main: '指导单位:中国青少年科技教育工作者协会',
            full: '中国宇航学会航天医学工程与空间生物学专业委员会',
            mainUrl: '#'
          },
          {
            icon: 'el-icon-office-building',
            main: '主办单位:中国国际科学技术合作协会',
            full: '',
            mainUrl: 'http://www.caistc.com'
          },
          {
            icon: 'el-icon-office-building',
            main: '承办单位: 江苏省青少年科技教育协会',
            full: '深圳市产学研合作促进会',
            mainUrl: '#'
          },
          {
            icon: 'el-icon-office-building',
            main: '备案:京ICP备2024089304号',
            full: '',
            mainUrl: 'https://beian.miit.gov.cn'
          }
        ],

        guide: [
          '北京青少年科技教育协会', '重庆市青少年科技教育工作者协会',
          '甘肃省青少年科技教育协会', '广东省青少年科技教育协会',
          '广西青少年科技教育协会', '河北省青少年科技辅导员协会',
          '河南省青少年科技教育协会', '湖南省机器人科技教育学会',
          '辽宁省青少年科技辅导员协会', '山东省青少年科技教育协会',
          '陕西省青少年科技教育协会', '四川省青少年科技教育协会',
          '浙江省青少年科技教育协会'
        ],

        ruleForm: {
          password: '',
          username: '',
          phone: ''
        },

        // 青少年人工智能挑战- 人工智能挑战
        challengeId: '',
        // 人文交流 - 科技外交官摇篮计划
        communicateId: '',
        // 科学沙龙 - 科技辅导员培训
        trainId: '',
        // 活动服务套餐 - 科技成果活动展览
        showId: '',
        // 科学记录者影像征集
        collectId: ''
      }
    },

    mounted() {
      this.loadMenu()
    },

    methods: {
      regist() {
        http
          .post('users/register', this.ruleForm)
          .then(response => {
            // 处理响应数据
            console.log(response)
          })
          .catch(error => {
            // 处理错误
            console.log(error)
          })
      },

      longin() {
        http
          .post('users/login', this.ruleForm)
          .then(response => {
            // 处理响应数据
            console.log(response)
          })
          .catch(error => {
            // 处理错误
            console.log(error)
          })
      },

      // 菜单
      loadMenu() {
        http
          .post('menu/all')
          .then(response => {
            // 处理响应数据
            console.log('首页菜单')
            const list = response.data.data
            console.log(list)

            for (let index = 0; index < list.length; index++) {
              const element = list[index]
              if (index == 0) {
                this.challengeId = element._id
              } else if (index == 1) {
                this.communicateId = element._id
              } else if (index == 2) {
                this.trainId = element._id
              } else if (index == 3) {
                this.showId = element._id
              } else {
                this.collectId = element._id
              }
            }
          })
          .catch(error => {
            // 处理错误
            console.log(error)
          })
      },

      friendLink() {
        console.log('ddffgghhh')
        window.location.href = 'http://www.caistc.com'
      },

      submitForm() {}
    }
  }
</script>

<style lang="less" scoped>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .center {
      flex: 1;
    }
  }

  /* 
  enter-active 定义进入过渡的结束状态
  leave-active 定义离开过渡的结束状态
 */
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all 0.5s;
  }

  /* 
  enter定义进入过渡的开始状态
 */
  .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
  }

  /* 
  leave-to离场动画结束后的状态
 */
  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .header {
    margin-top: 0;

    .fuction {
      position: relative;

      .setting {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 10px 0;

        .regist-btn {
          color: white;
          text-align: center;
          padding: 10px 20px;
          background-color: #67c23a;
          margin-right: 10px;
        }
      }
    }
  }

  .header_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background: #1632dc;

    a {
      flex: 1;
      background-color: #1f49d2;
      height: 100%;
      padding: 10px 0;
      color: white;
      text-decoration: none;
      // border: 1px solid white;
      font-weight: bold;
      font-size: 16px;
    }

    a.active {
      background-color: #112573;
      font-weight: bold;
      opacity: 0.8;
    }

    a.exac-active {
      background-color: #112573;
    }
  }

  // 底部
  .bottom {
    display: flex;
    margin-top: 50px;
    height: 150px;
    width: 100%;
    background: #1632dc;
    justify-content: space-between;
    align-items: center;

    .wrap {
      width: 500px;

      p {
        display: flex;
        padding-left: 20px;
        align-items: center;

        a {
          margin-left: 10px;
          color: white;
          font-size: 10px;
          text-decoration: none;
        }
      }
    }

    .guide {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      width: 440px;

      .title {
        text-align: left;
        width: 100%;
      }

      a {
        margin-right: 10px;
        color: white;
        font-size: 10px;
        text-decoration: none;
      }
    }

    .link {
      width: 190px;
      height: 50px;
    }

    .bottom-code {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;

      .wechat {
        margin-top: 4px;
        color: white;
        font-size: 10px;
      }
    }
  }
</style>